import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import ParseContent from 'components/shared/ParseContent'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import ButtonDefault from 'components/elements/ButtonDefault'

const Wrapper = styled.section``

const InnerWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
`

const ProcessWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.face.light};
`

const StyledTitleDefault = styled(TitleDefault)``

const SubTitleWrapper = styled(motion.div)`
  &:hover {
    cursor: pointer;
  }
`

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.font.size['18']};
  color: ${(props) => props.theme.color.text.dark};
  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size['16']};
  }
  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.font.size['14']};
  }
  @media (max-width: 575px) {
    font-size: ${(props) => props.theme.font.size['12']};
  }
`

const AfterSubTitle = styled.div`
  font-size: ${(props) => props.theme.font.size['21']};
  font-weight: ${(props) => props.theme.font.weight.l};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size['16']};
    color: ${(props) => props.theme.color.text.dark};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  & > a {
    background-color: transparent;
    color: ${(props) => props.theme.color.text.dark};
    border: 1px solid ${(props) => props.theme.color.face.dark};
    padding: 15px 10px;
    &:hover {
      color: ${(props) => props.theme.color.text.dark};
    }
  }
`

interface ProcessProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Process
}

const Process: React.FC<ProcessProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  return (
    <Wrapper className="container my-5 py-5" id="werkwijze">
      {fields?.title && (
        <StyledTitleDefault>{fields?.title}</StyledTitleDefault>
      )}

      <div className="row justify-content-center">
        <InnerWrapper className="col-lg-9 pb-5">
          {fields?.step?.map((step, index: number) => {
            const isOpen = index === currentIndex

            return (
              <ProcessWrapper className="mx-lg-5 mx-2 pt-5 pb-3">
                <SubTitleWrapper
                  onClick={() => {
                    if (index === currentIndex) {
                      setCurrentIndex(null)
                    } else {
                      setCurrentIndex(index)
                    }
                  }}
                >
                  <div className="d-flex w-100 align-items-center">
                    <SubTitle className="w-75 mb-0">{step?.subtitle}</SubTitle>
                    <AfterSubTitle className="d-flex justify-content-end w-25 mr-md-3">
                      {isOpen ? '-' : '+'}
                    </AfterSubTitle>
                  </div>
                </SubTitleWrapper>
                <AnimatePresence initial={false}>
                  {isOpen && (
                    <motion.div
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                          overflow: 'hidden',
                        },
                        collapsed: {
                          opacity: 0,
                          height: 0,
                          overflow: 'hidden',
                        },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      <Content content={step?.description} className="py-4" />
                    </motion.div>
                  )}
                </AnimatePresence>
              </ProcessWrapper>
            )
          })}
          <div className="d-flex justify-content-center mt-5">
            <StyledButtonDefault
              to={fields?.link?.url || ''}
              className="text-center"
            >
              {fields?.link?.title}
            </StyledButtonDefault>
          </div>
        </InnerWrapper>
      </div>
    </Wrapper>
  )
}

export default Process
